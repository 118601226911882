export const SET_IS_LOGGEDIN = "SET_IS_LOGGEDIN";
export const SET_IS_ADMIN = "SET_IS_ADMIN";
export const SET_IS_LOADING = "SET_IS_LOADING";
export const SET_IS_POST_LOADING = "SET_IS_POST_LOADING";
export const SET_PROVIDER_ITEMS = "SET_PROVIDER_ITEMS";
export const GET_CLIENTS = "GET_CLIENTS";
export const SET_USER = "SET_USER";
export const GET_REVENUE_CATEGORY = "GET_REVENUE_CATEGORY";
export const GET_EXPENSES_CATEGORY = "GET_EXPENSES_CATEGORY";
export const GET_REVENUES = "GET_REVENUES";
export const GET_REVENUE_BY_ID = "GET_REVENUE_BY_ID";
export const GET_EXPENSE = "GET_EXPENSE";
export const GET_EXPENSE_BY_ID = "GET_EXPENSE_BY_ID";
export const GET_PURCHASES = "GET_PURCHASES";
export const GET_PURCHASES_BY_ID = "GET_PURCHASES_BY_ID";
export const GET_SUPPLIERS = "GET_SUPPLIERS";
export const GET_EMPLOYEES = "GET_EMPLOYEES";
export const GET_TOTAL_PAGES = "GET_TOTAL_PAGES";
export const GET_CODES = "GET_CODES";
export const GET_ORDERS = "GET_ORDERS";
export const GET_TRIPS = "GET_TRIPS";
export const GET_ACCOUNT_STATEMENT = "GET_ACCOUNT_STATEMENT";
export const GET_FINAL_BALANCE = "GET_FINAL_BALANCE";
export const GET_OLD_BALANCE = "GET_OLD_BALANCE";
export const GET_REVENUE_REPORT = "GET_REVENUE_REPORT";
export const GET_REVENUE_OLD_BALANCE = "GET_REVENUE_OLD_BALANCE";
export const GET_REVENUE_FINAL_BALANCE = "GET_REVENUE_FINAL_BALANCE";
export const GET_EXPENSE_REPORT = "GET_EXPENSE_REPORT";
export const GET_EXPENSE_OLD_BALANCE = "GET_EXPENSE_OLD_BALANCE";
export const GET_EXPENSE_FINAL_BALANCE = "GET_EXPENSE_FINAL_BALANCE";
export const GET_PURCHASE_REPORT = "GET_PURCHASE_REPORT";
export const GET_PURCHASE_OLD_BALANCE = "GET_PURCHASE_OLD_BALANCE";
export const GET_PURCHASE_FINAL_BALANCE = "GET_PURCHASE_FINAL_BALANCE";
export const ORDER_DETAILS = "ORDER_DETAILS";
export const GET_CITIES = "GET_CITIES";
export const GET_SCHEDULED_TRIPS = "GET_SCHEDULED_TRIPS";
export const GET_SCHEDULED_TRIP = "GET_SCHEDULED_TRIP";
export const GET_USER_PERMISSIONS = "GET_USER_PERMISSIONS";
export const GET_MAIN_PRODUCTS = "GET_MAIN_PRODUCTS";
export const GET_PRODUCT_BY_ID = "GET_PRODUCT_BY_ID";
